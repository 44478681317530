import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/parkingMerchant/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/parkingMerchant/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/parkingMerchant/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function bindChannelWechat(merchantId) {
  var formData = new FormData();

  formData.append("expire_seconds", "2592000");
  formData.append("action_name", "QR_LIMIT_STR_SCENE");
  formData.append("scene_str", process.env.VUE_APP_BIND_CHANNEL_WECHAT_CODE + "," + merchantId);

  return request.post(constant.serverUrl + "/wechat/qrcode/create", formData);
}

export default {
  pageList, create, edit, add, update, remove, batchRemove,bindChannelWechat
}