<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/parkingMerchant">商户管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="所属物业公司" prop="companyId">
        <el-select-tree
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
          style="width: 230px"
          size="mini"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="商户名称" prop="merchantName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.merchantName"
        ></el-input>
      </el-form-item>
      <el-form-item label="优惠券类型" prop="type" v-if="false">
        <el-select
          filterable
          placeholder="请选择"
          style="width: 180px"
          v-model="queryModel.type"
          size="mini"
        >
          <el-option
            v-for="result in typeResult"
            :key="result.value"
            :label="result.name"
            :value="result.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增商户</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        :index="indexMethod"
        width="50"
      ></el-table-column>

      <el-table-column
        prop="merchantName"
        label="商户名称"
        width="180"
      ></el-table-column>

      <el-table-column
        prop="companyName"
        label="所属物业公司"
        width="180"
      ></el-table-column>

      <el-table-column
        prop="userName"
        label="商户账号"
        width="180"
      ></el-table-column>

      <el-table-column prop="enableCoupon" label="停车抵用卷" width="200">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.enableCoupon"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateEnabled(row)"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column
        prop="reduce"
        label="抵用卷金额"
        width="180"
      ></el-table-column>

      <el-table-column prop="enableMember" label="车辆白名单" width="200">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.enableMember"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="updateEnabled(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        prop="memberLeaveTime"
        label="固定离场时间"
        width="180"
      ></el-table-column>

      <el-table-column label="操作" width="250" fixed="right">
        <template slot-scope="{ row }">
          <el-row>
            <el-col>
              <el-link type="warning" @click="handleEdit(row)">编辑</el-link>-
              <el-link type="danger" @click="handleDelete(row)">删除</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <parkingMerchant-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></parkingMerchant-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import ParkingMerchantDetail from "./parkingMerchant-detail";

import parkingMerchantApi from "@/api/base/parkingMerchant";
import dataDictionaryApi from "@/api/sys/dataDictionary";

import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: "baseParkingMerchantList",
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        merchantName: "",
        companyId: "",
        type: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      typeResult: [],
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      bindWechatVisible: false,
      bindWechatUrl: "",
      bindWechatLoading: false,
      showBindMerchantModal: false,
      merchantId: "",
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    dataDictionaryApi
      .findByCatalogName({
        catalogName: "优惠券类型",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeResult = jsonData.data;
      });
  },
  methods: {
    indexMethod(index) {
      return (this.pageIndex - 1) * this.pageSize + (index + 1);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("merchantName", self.queryModel.merchantName);
      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }
      formData.append("type", self.queryModel.type);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      parkingMerchantApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          parkingMerchantApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        parkingMerchantApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showBindMerchantModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },

    updateEnabled(row) {
      parkingMerchantApi.update(row);
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "parkingMerchant-detail": ParkingMerchantDetail,
    "el-select-tree": SelectTree,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>