
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'120px'"
      >
        <el-form-item label="商户名称" prop="merchantName">
          <el-input
            v-model="formModel.merchantName"
            placeholder="请输入商户名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属物业公司" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
            style="width: 330px"
            size="large"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="商户账号" prop="userName">
          <el-input
            :disabled="formModel.id != null"
            v-model="formModel.userName"
            placeholder="请输入商户账号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户密码" prop="password">
          <el-input
            v-model="formModel.password"
            placeholder="请输入商户密码"
            style="width: 300px"
          ></el-input>
        </el-form-item>

        <el-row>
          <el-col :span="8">
            <el-form-item label="停车抵扣券" prop="enableCoupon">
              <el-switch
                v-model="formModel.enableCoupon"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="抵扣券金额" prop="reduce">
              <el-input-number
                :precision="0"
                v-model="formModel.reduce"
                placeholder="请输入金额"
                style="width: 180px"
                :min="1"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="车辆白名单" prop="enableMember">
              <el-switch
                v-model="formModel.enableMember"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="离场时间" prop="memberLeaveTime">
              <el-time-picker
                v-model="formModel.memberLeaveTime"
                format="HH:mm"
                :clearable="false"
                value-format="HH:mm"
                style="width: 180px"
                placeholder="请选择离场时间"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingMerchantApi from "@/api/base/parkingMerchant";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";

export default {
  components: {
    "el-select-tree": SelectTree,
  },
  props: ["businessKey", "title"],
  data() {
    return {
      myMemberLeaveTime: [
        { required: true, message: "请选择离场时间", trigger: "blur" },
      ],
      formModel: {},
      ruleValidate: {
        merchantName: [
          { required: true, message: "商户名称不能为空", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "所属物业公司不能为空", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "商户账号不能为空", trigger: "blur" },
        ],

        password: [
          { required: true, message: "商户密码不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      typeResult: [],
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
    
    dataDictionaryApi
      .findByCatalogName({
        catalogName: "优惠券类型",
      })
      .then((response) => {
        var jsonData = response.data;
        this.typeResult = jsonData.data;
      });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return parkingMerchantApi.add(self.formModel);
            } else {
              return parkingMerchantApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              //self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return parkingMerchantApi.create();
      } else {
        return parkingMerchantApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
          if (!self.formModel.memberLeaveTime) {
            self.formModel.memberLeaveTime = "14:00";
          }
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>